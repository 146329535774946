import React from 'react';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import { StaticImage } from 'gatsby-plugin-image';
// import PageHeader from '../components/PageHeader';
import Section from '../components/layout/Section';
import Btn, { BtnGroup } from '../components/Btn'

const NotFound = () => (
  <Layout>
    <Seo title={`404 Error`} />
    <Section sectionName={`404 Error`}>

      <div className={`flex flex-row -mx-8 justify-start`}>
        <div className={`flex-trueauto px-8 md:block hidden self-center`}>
        
        <StaticImage 
                alt={`Hohn`}
                src={`../images/about/hohn_404_error.jpg`}
                
              />
        </div>
        <div className={`flex-trueauto md:min-w-[55%] px-8`}>
              
              <div className={`text-center space-y-10`}>
                <h1 className={`lg:text-8xl text-7xl font-brand leading-[115%]`}>Page <br/>Not <br/>Found</h1>
                <p>
                  Looks like you're lost.
                </p>
                <BtnGroup style={{justifyContent: 'center'}}>
                  <Btn buttonTitle={`Home`} isLink={true} linkTo={`/`} />
                  <Btn buttonTitle={`Contact`} isLink={true} linkTo={`/contact`} />
                </BtnGroup>
              </div>
        </div>
      </div>

    </Section>
  </Layout>
);

export default NotFound;
