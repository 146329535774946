import React from "react"
import * as sectionStyle from "../../css/section.module.scss"

const Section = ( {sectionName, bgColor, containerSize, children} ) => {

	if (!sectionName) return null

	const sectionSlug = sectionName.replace(/\s+/g, '-').toLowerCase()

	const parseBg = () => {
		if (!bgColor) {
			return 'transparent'
		}

		return bgColor
	}

	const bgStyles = {
		backgroundColor: bgColor
	}

	let containerClassName = 'container-lg';
	containerSize === 'xl' ? (
		containerClassName = 'container-xl'
	) : containerSize === 'sm' ? (
		containerClassName = 'max-w-5xl'
	) : (
		containerClassName = 'container-lg'
	);

	return (

		<section id={sectionSlug} className={`${sectionStyle.section} ${sectionSlug}-section`}
			style={bgStyles}
		>
			<div className={`${containerClassName} md:px-8 px-4 mx-auto z-10 relative`}>
			{children}
			</div>

		</section>
	)
}

export default Section
